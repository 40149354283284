import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'
import { PostLoginRequest } from 'types/ApiRequest'
import { PostLoginResponse } from 'types/ApiResponse'

export const postLogin = async (
  params: PostLoginRequest,
  device_certificate: boolean,
) => {
  // 手違いで文字列の"true"/"false"が入ってしまうのが怖いので、念の為明示的にbooleanで比較
  // https://github.com/OnetapInc/locked-api/blob/develop/app/controllers/api/v1/teams_controller.rb#L177
  let endpoint = `${fullUrl()}/login`
  if (device_certificate === true) {
    endpoint = `${fullUrl('api-certificate')}/users/login`
  }
  const { data } = await axiosClient.post<PostLoginResponse>(endpoint, params)
  return data
}
