import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { postLogin } from 'api/postLogin'
import { cookies } from 'scripts/cookies'
import { FingerPrint, FingerPrintHash } from 'types/FingerPrint'
import { Navigator } from 'types/window'
import { UADataKeys } from 'scripts/UADataKeys'
import { useTranslation } from 'react-i18next'
import { Loading } from 'presentation/components/Loading'
import { SendLockedSessionTokenToExtension } from 'scripts/sendMessageToExtension'
import { getDeviceUuid } from 'scripts/getDeviceUuid'
import OpenEyeSvg from 'images/openEye.svg'
import CloseEyeSvg from 'images/closeEye.svg'
import { ExternalIdp } from 'types/ExternalIdp'
import { fullUrl } from 'scripts/fullUrl'
import { sentryCaptureMessage } from 'scripts/sentryCaptureMessage'
import { getApps } from 'api/getApps'
import { convertAppsToExtensionApps } from 'scripts/convertAppsToExtensionApps'
import { SendUrlMatchFormBasedAuthParams } from 'scripts/sendMessageToExtension/sendUrlMatchFormBasedAuthParams'

interface Props {
  fingerprint: FingerPrint
  fingerprintHash: FingerPrintHash
  userAgent: string
  teamId: number
  externals: ExternalIdp[]
  ipAddress: string
  appClientKey: string | null | undefined
  usePasswordAuthenticationInWeb: boolean | undefined
  hasValidForcedPasswordLoginDisplayToken: boolean
  canUseDeviceCertificate: boolean
}

export const Main: React.FC<Props> = ({
  fingerprint,
  fingerprintHash,
  userAgent,
  teamId,
  externals,
  ipAddress,
  appClientKey,
  usePasswordAuthenticationInWeb,
  hasValidForcedPasswordLoginDisplayToken,
  canUseDeviceCertificate,
}) => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [openSnackbar] = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)
  const { t, i18n } = useTranslation()
  const [postLoginLoading, setPostLoginLoading] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'pass':
        setPass(event.target.value)
        break
      default:
        break
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Enter') {
      event.preventDefault()
      handleClick()
    }
  }

  const handleClick = async () => {
    // TODO: これあとで確認
    const VALIDATE = !pass || !email || email.length > 256
    if (VALIDATE) {
      openSnackbar('ログインに失敗しました', 'wrongInputSnackbar')
      return
    }

    setPostLoginLoading(true)

    cookies.setLockedHashToCookie(fingerprintHash)

    const ua = await (
      navigator as Navigator
    ).userAgentData?.getHighEntropyValues(UADataKeys)

    const params = {
      team_id: teamId,
      session: {
        email,
        fingerprint,
        user_ip: ipAddress,
        password: pass,
        hash: fingerprintHash,
        user_agent: userAgent,
        client_hint: {
          platform: ua?.platform ?? '',
          platform_version: ua?.platformVersion ?? '',
          brands: ua?.brands,
        },
        device_uuid: getDeviceUuid(),
      },
    }
    const {
      action,
      device_uuid,
      event_id,
      session_token,
      session_token_expired_at_minutes,
      login_try_token,
      client_error_message,
    } = await postLogin(params, canUseDeviceCertificate)
    if (device_uuid) {
      cookies.setDeviceUuidToCookie(device_uuid)
    }
    switch (action) {
      case 'allow': {
        if (typeof session_token_expired_at_minutes === 'undefined') {
          // error
          openSnackbar('ログインに失敗しました。', 'denySnackbar')
          return
        }
        const expires = cookies.generateSessionTokenExpires(
          session_token_expired_at_minutes,
        )
        cookies.setSessionTokenToCookie(session_token ?? '', expires)
        const params = { lockedSessionToken: session_token ?? '' }
        const result = await new SendLockedSessionTokenToExtension(
          params,
        ).exec()
        if (result.success) {
          // 何もしない
        } else {
          // 何もしない
        }
        getApps({
          sessionToken: session_token ?? '',
        }).then(async (response) => {
          if (response.status !== 200) {
            return
          }
          const extensionApps = convertAppsToExtensionApps(response.data.apps)
          await new SendUrlMatchFormBasedAuthParams({
            apps: extensionApps,
          }).exec()
        })
        const url = new URL(`${location.origin}/apps`)
        if (typeof appClientKey === 'string' && appClientKey.length > 0) {
          url.searchParams.set('app_client_key', appClientKey)
        }

        navigate(url.href.replace(location.origin, ''), {
          state: {
            snackbarText: 'ログインしました',
          },
        })
        break
      }
      case 'verify': {
        cookies.setLockedEventIdToCookie(event_id ?? '')
        const url = new URL(`${location.origin}/verify`)
        if (appClientKey) {
          url.searchParams.set('app_client_key', appClientKey)
        }
        navigate(url.href.replace(location.origin, ''))
        break
      }
      case 'verify_secret_question': {
        cookies.setLockedEventIdToCookie(event_id ?? '')
        const url = new URL(`${location.origin}/verifySecretQuestion`)
        if (appClientKey) {
          url.searchParams.set('app_client_key', appClientKey)
        }
        if (login_try_token) {
          url.searchParams.set('token', login_try_token)
        }
        navigate(url.href.replace(location.origin, ''))
        break
      }
      case 'verify_totp': {
        cookies.setLockedEventIdToCookie(event_id ?? '')
        const url = new URL(`${location.origin}/verifyTotp`)
        if (appClientKey) {
          url.searchParams.set('app_client_key', appClientKey)
        }
        if (login_try_token) {
          url.searchParams.set('token', login_try_token)
        }
        navigate(url.href.replace(location.origin, ''))
        break
      }
      case 'deny': {
        const message = client_error_message || 'ログインに失敗しました。'
        openSnackbar(message, 'denySnackbar')
        break
      }
      default: {
        openSnackbar('通信に失敗しました。', 'connectionFailSnackbar')
        break
      }
    }

    setPostLoginLoading(false)
  }

  const goExternalAuthorization = async (external: ExternalIdp) => {
    window.location.href = `${fullUrl()}/saml/sso/${external.idp_type}/${
      external.id_hash
    }?RelayState=${appClientKey}`
  }

  const showPasswordAuthentication =
    hasValidForcedPasswordLoginDisplayToken || usePasswordAuthenticationInWeb

  return (
    <>
      <p className="text-xl">{t('ログイン')}</p>
      {showPasswordAuthentication && (
        <>
          <div className="w-full px-6">
            <p className="text-xs font-semibold text-gray-700 pb-1">
              {t('メールアドレス')}
            </p>
            <input
              aria-label="email"
              className="rounded px-2 w-full h-8 border border-black"
              data-test="email"
              type="text"
              name="email"
              value={email}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="w-full px-6 pt-4">
            <p className="text-xs font-semibold text-gray-700 pb-1">
              {t('パスワード')}
            </p>
            <div className="w-full relative">
              <input
                aria-label="password"
                className="rounded pl-2 pr-8 w-full h-8 border border-black"
                data-test="pass"
                type={showPassword ? 'text' : 'password'}
                name="pass"
                value={pass}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                className="cursor-pointer absolute top-0 right-0 flex items-center mr-2"
                style={{ height: 32 }}
              >
                {showPassword ? (
                  <img className="h-6" src={OpenEyeSvg}></img>
                ) : (
                  <img className="h-6" src={CloseEyeSvg}></img>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-6 mt-8">
            <button
              aria-label="submit"
              className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
              data-test="submit"
              onClick={handleClick}
              disabled={postLoginLoading}
            >
              {postLoginLoading ? (
                <div
                  className="flex justify-center p-4"
                  style={{ width: '100%' }}
                >
                  <Loading width={20} height={20}></Loading>
                </div>
              ) : (
                <>{t('ログインする')}</>
              )}
            </button>
          </div>
        </>
      )}
      {externals.length > 0 && showPasswordAuthentication && (
        <hr
          style={{ width: '100%', marginBottom: '-15px', marginTop: '20px' }}
        />
      )}
      {showPasswordAuthentication === false && (
        <div style={{ height: '20px', width: '100%' }} />
      )}
      {externals.map((external) => (
        <div className="w-full px-6 mt-8">
          <button
            className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
            data-test="externalIdpSubmit"
            onClick={() => goExternalAuthorization(external)}
            disabled={postLoginLoading}
            style={{
              backgroundColor: external.color_hex,
              borderColor: external.color_hex,
            }}
          >
            {i18n.language === 'ja' && `${external.label}でログイン`}
            {i18n.language === 'en' && `Log in with ${external.label}`}
            {i18n.language === 'zh' && `用${external.label}登录`}
          </button>
        </div>
      ))}
      {showPasswordAuthentication === false && (
        <div style={{ height: '20px', width: '100%' }} />
      )}
      <div className="mt-5">
        <a
          className="locked-help-link"
          href="https://www.notion.so/afa58f9f8c7b4e88acfcc43505da543f"
          target="_blank"
        >
          {t('ログインに関するヘルプ')}
        </a>
      </div>
      {showPasswordAuthentication && (
        <div className="mt-2">
          <a className="locked-help-link" href="/passwordResetRequest">
            {t('パスワードを忘れた方はこちら')}
          </a>
        </div>
      )}
      <div className="mb-8" />
    </>
  )
}
