import { AxiosResponse } from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { checkAppAuthorityResponse } from 'types/ApiResponse'
import { checkAppAuthorityRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const checkAppAuthority = async (
  params: checkAppAuthorityRequest,
  device_certificate: boolean,
): Promise<AxiosResponse<checkAppAuthorityResponse>> => {
  // 手違いで文字列の"true"/"false"が入ってしまうのが怖いので、念の為明示的にbooleanで比較
  // https://github.com/OnetapInc/locked-api/blob/develop/app/controllers/api/v1/teams_controller.rb#L177
  if (device_certificate === true) {
    let endpoint = `${fullUrl('api-certificate')}/apps/authorize`
    return await axiosClient.post<checkAppAuthorityResponse>(endpoint, {
      params,
    })
  } else {
    let endpoint = `${fullUrl()}/apps/${params.app_id}/authorize`
    return await axiosClient.get<checkAppAuthorityResponse>(endpoint, {
      params,
    })
  }
}
